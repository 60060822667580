<template>
  <div class="mNotify_login_type" id="">
    <div class="login_type">
      <img
        id="logo"
        src="@/assets/img/bms-01-text.png"
        alt="mNotify logo"
        width="100"
      />
    </div>
    <div class="mNotify_login_type_container">
      <div class="mNotify_login_type_inner_container">
        <h4 class="what_do_want_to_do text-center">
          {{ $t("dashboard.login_type.today") }}
        </h4>

        <div class="centered">
          <div
            class="Nnotify_login_type_inner_containr_card margin__right"
            @click="setActive('sms')"
            :class="[active == 'sms' ? 'active' : '']"
          >
            <div
              class="Nnotify_login_type_inner_containr_card_inner"
              @mouseover="showMessageDark = false"
              @mouseleave="showMessageDark = true"
            >
              <img
                v-if="
                  (active == '' &&
                    active !== 'sms' &&
                    checkShowMessageDark == false) ||
                  (active != '' &&
                    active !== 'sms' &&
                    checkShowMessageDark == false)
                "
                src="../../assets/Message_white.svg"
                width="40"
                height="40"
                alt="message box"
              />
              <img
                v-if="checkShowMessageDark == true"
                width="40"
                height="40"
                src="../../assets/Message_dark.svg"
                alt="message box"
              />
              <img
                v-if="active === 'sms'"
                width="40"
                height="40"
                src="../../assets/Message_white.svg"
                alt="message box"
              />
              <p class="send">{{ $t("dashboard.login_type.sms") }}</p>
            </div>
          </div>
          <div
            class="Nnotify_login_type_inner_containr_card right_card"
            :class="[active == 'voice' ? 'active' : '']"
            @click="setActive('voice')"
            v-if="!isFrenchUser"
          >
            <div
              class="Nnotify_login_type_inner_containr_card_inner"
              @mouseover="showCallingDark = false"
              @mouseleave="showCallingDark = true"
            >
              <img
                v-if="checkShowVoiceDark == true"
                class="color:#fff"
                src="../../assets/Calling_dark.svg"
                width="40"
                height="40"
                alt="phone"
              />
              <img
                v-if="
                  (active == '' &&
                    active !== 'voice' &&
                    checkShowVoiceDark == false) ||
                  (active != '' &&
                    active !== 'voice' &&
                    checkShowVoiceDark == false)
                "
                class="color:#fff"
                src="../../assets/Calling_white.svg"
                width="40"
                height="40"
                alt="phone"
              />
              <img
                v-if="active === 'voice'"
                class="color:#fff"
                src="../../assets/Calling_white.svg"
                width="40"
                height="40"
                alt="phone"
              />
              <p class="send">{{ $t("dashboard.login_type.voice") }}</p>
            </div>
          </div>
        </div>
        <div class="">
          <p class="set_option">
            <el-checkbox
              style="width: 15px; height: 15px"
              id="mNotifyTerms"
              v-model="checkBox"
              name="mNotifyTerms"
            ></el-checkbox>
            {{ $t("dashboard.login_type.set_default") }}<br />
            {{ $t("dashboard.login_type.changed_later") }}
          </p>
        </div>
        <div class="enter_container">
          <el-button
            @click="returnToDashboard"
            class="enter"
            :class="[active != '' ? 'btn_active' : '']"
            :disabled="!active"
          >
            {{ $t("dashboard.login_type.proceed") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from "../../services/storage.js";
var storage = new Storage();

export default {
  data() {
    return {
      active: "",
      showMessageDark: true,
      showCallingDark: true,
      checkBox: false,
    };
  },
  computed: {
    checkShowMessageDark() {
      if (this.active != "sms") {
        if (this.showMessageDark == true) {
          return true;
        } else return false;
      }
      return false;
    },
    checkShowVoiceDark() {
      if (this.active != "voice") {
        if (this.showCallingDark == true) {
          return true;
        } else return false;
      }
      return false;
    },
    isFrenchUser() {
      return storage.getSavedState("currentUser").country == "CI";
    },
    user() {
      return storage.getSavedState("currentUser");
    },
  },
  methods: {
    returnToDashboard() {
      let storage = new Storage();
      storage.saveAndDelete("defaultLoginType", this.checkBox);
      storage.saveAndDelete(
        "loginType",
        this.active == "sms" ? "sms" : "voice_messages"
      );
      this.$router.push("/overview").catch((err) => {});
    },
    setActive(type) {
      if (type == "sms") {
        this.active = "sms";
        this.showMessageDark = false;
        this.showCallingDark = true;
        sessionStorage.setItem("userDefault", "sms");
      } else if (type != "" && type != "sms") {
        this.active = "voice";
        this.showCallingDark = false;
        this.showMessageDark = true;
        sessionStorage.setItem("userDefault", "voice_messages");
      }
    },
    handleKeyPress(e) {
      if (e.keyCode == 13 && this.active != "") {
        this.returnToDashboard();
      }
    },
  },
  mounted() {
    document.addEventListener("keyup", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyPress);
  },
};
</script>

<style scoped>
#logo {
  width: 15rem;

  /* margin-top: 35px; */
}

.login_type {
  text-align: center;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mNotify_login_type {
  width: 100%;
  background-color: #fff;
}

.mNotify_login_type_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.mNotify_login_type_inner_container {
  /* 702 for 3 cards */
  max-width: 504;
  margin-left: auto;
  margin-right: auto;
}

.what_do_want_to_do {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #575761;
  padding-bottom: 27px;
}

.Nnotify_login_type_inner_containr_card {
  width: 198px;
  height: 258px;
  background: #f6f6f6;
  border-radius: 10px;
  display: inline-block;
  padding-top: auto;
  padding-bottom: auto;
  color: #484a4f;
  transition: all 0.5s;
  cursor: pointer;
}

.margin__right {
  margin-right: 37px;
}

.Nnotify_login_type_inner_containr_card_inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.send {
  padding-top: 37px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
}

.Nnotify_login_type_inner_containr_card:hover,
.active {
  background: #f7921c;
  color: #fff;
}

.set_option {
  font-family: "Graphik Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #575761;
  padding-top: 31px;
  text-align: center;
}

.enter {
  margin-top: 10px;
  padding: 13px 20px;
  text-align: center;
  height: 51;
  font-family: "Graphik Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: #575761;
  background: #f6f6f6;
  transition: all 0.5s;
  position: fixed;
  bottom: min(10%, 100px);
  z-index: 1;
}

.btn_active {
  background-color: #f7921c !important;
  color: #fff !important;
}

.enter_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @media screen and (max-width: 768px) {
    #logo{
        width: 100px;
    }
} */

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 500px) {
  .mNotify_login_type_inner_container {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  .Nnotify_login_type_inner_containr_card {
    width: 45%;
    height: 250px;
  }

  .margin__right {
    margin-right: 0;
  }

  .right_card {
    float: right;
  }
}

.login_type img {
  width: 10rem !important;
  object-fit: contain;
}

@media screen and (max-width: 400px) {
  .Nnotify_login_type_inner_containr_card {
    width: 45%;
    height: 180px;
  }

  .send {
    font-size: 13px;
  }
}
</style>
