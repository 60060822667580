<template>
  <div class="slider_wrapper">
    <div class="slider_text">
      <h2
        style="margin-bottom: 0"
        class="mb-4 is-relative notranslate"
        v-html="$t('auth.sliders.slide_1_header')"></h2>
      <p class="notranslate text-long">
        {{ $t("auth.sliders.slide_1_text") }}
      </p>
    </div>
    <div class="slider_image">
      <img
        class="image"
        style="animation-duration: 0s"
        src="../../../assets/img/Call-center-bro.png"
        alt="illustrative image of a call center"
        width="1"
        height="1" />
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .__silder_wraper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slider_text {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 170px;
    // background-color: springgreen;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 40px;
      color: #000000;
      text-align: center;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 30px;
      color: #000000;
      opacity: 0.4;
      max-width: 450px;
      text-align: center;
    }
  }

  .slider_image {
    width: 100%;
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image {
      width: 450px;
      max-width: 80%;
      object-fit: contain;
      height: 100%;
      background-origin: center;
    }
  }

  .iconbutton {
    width: 42px;
    height: 42px;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;

    &:hover,
    &:active {
      background: #000;
    }

    img {
      width: 100%;
    }
  }
  .slider_image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-long {
    max-width: 950px;
  }
</style>
