var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mNotify_login_type",attrs:{"id":""}},[_vm._m(0),_c('div',{staticClass:"mNotify_login_type_container"},[_c('div',{staticClass:"mNotify_login_type_inner_container"},[_c('h4',{staticClass:"what_do_want_to_do text-center"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.login_type.today"))+" ")]),_c('div',{staticClass:"centered"},[_c('div',{staticClass:"Nnotify_login_type_inner_containr_card margin__right",class:[_vm.active == 'sms' ? 'active' : ''],on:{"click":function($event){return _vm.setActive('sms')}}},[_c('div',{staticClass:"Nnotify_login_type_inner_containr_card_inner",on:{"mouseover":function($event){_vm.showMessageDark = false},"mouseleave":function($event){_vm.showMessageDark = true}}},[(
                (_vm.active == '' &&
                  _vm.active !== 'sms' &&
                  _vm.checkShowMessageDark == false) ||
                (_vm.active != '' &&
                  _vm.active !== 'sms' &&
                  _vm.checkShowMessageDark == false)
              )?_c('img',{attrs:{"src":require("../../assets/Message_white.svg"),"width":"40","height":"40","alt":"message box"}}):_vm._e(),(_vm.checkShowMessageDark == true)?_c('img',{attrs:{"width":"40","height":"40","src":require("../../assets/Message_dark.svg"),"alt":"message box"}}):_vm._e(),(_vm.active === 'sms')?_c('img',{attrs:{"width":"40","height":"40","src":require("../../assets/Message_white.svg"),"alt":"message box"}}):_vm._e(),_c('p',{staticClass:"send"},[_vm._v(_vm._s(_vm.$t("dashboard.login_type.sms")))])])]),(!_vm.isFrenchUser)?_c('div',{staticClass:"Nnotify_login_type_inner_containr_card right_card",class:[_vm.active == 'voice' ? 'active' : ''],on:{"click":function($event){return _vm.setActive('voice')}}},[_c('div',{staticClass:"Nnotify_login_type_inner_containr_card_inner",on:{"mouseover":function($event){_vm.showCallingDark = false},"mouseleave":function($event){_vm.showCallingDark = true}}},[(_vm.checkShowVoiceDark == true)?_c('img',{staticClass:"color:#fff",attrs:{"src":require("../../assets/Calling_dark.svg"),"width":"40","height":"40","alt":"phone"}}):_vm._e(),(
                (_vm.active == '' &&
                  _vm.active !== 'voice' &&
                  _vm.checkShowVoiceDark == false) ||
                (_vm.active != '' &&
                  _vm.active !== 'voice' &&
                  _vm.checkShowVoiceDark == false)
              )?_c('img',{staticClass:"color:#fff",attrs:{"src":require("../../assets/Calling_white.svg"),"width":"40","height":"40","alt":"phone"}}):_vm._e(),(_vm.active === 'voice')?_c('img',{staticClass:"color:#fff",attrs:{"src":require("../../assets/Calling_white.svg"),"width":"40","height":"40","alt":"phone"}}):_vm._e(),_c('p',{staticClass:"send"},[_vm._v(_vm._s(_vm.$t("dashboard.login_type.voice")))])])]):_vm._e()]),_c('div',{},[_c('p',{staticClass:"set_option"},[_c('el-checkbox',{staticStyle:{"width":"15px","height":"15px"},attrs:{"id":"mNotifyTerms","name":"mNotifyTerms"},model:{value:(_vm.checkBox),callback:function ($$v) {_vm.checkBox=$$v},expression:"checkBox"}}),_vm._v(" "+_vm._s(_vm.$t("dashboard.login_type.set_default"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("dashboard.login_type.changed_later"))+" ")],1)]),_c('div',{staticClass:"enter_container"},[_c('el-button',{staticClass:"enter",class:[_vm.active != '' ? 'btn_active' : ''],attrs:{"disabled":!_vm.active},on:{"click":_vm.returnToDashboard}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.login_type.proceed"))+" ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login_type"},[_c('img',{attrs:{"id":"logo","src":require("@/assets/img/bms-01-text.png"),"alt":"mNotify logo","width":"100"}})])
}]

export { render, staticRenderFns }