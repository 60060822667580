<template>
  <div
    class="auth_wrapper"
    :class="[$route.path !== '/signup' ? 'full-screen-width' : '']">
    <div class="side_wrapper_with_animation">
      <div class="logo_inner">
        <img id="logo" src="../../assets/img/bms-01-text.png" alt="img" />
      </div>
      <div class="slider__inner">
        <main-slider />
        <!-- <component @forward="next" @backward="prev" v-bind:is="currentComponentfunction"></component> -->
      </div>
    </div>
    <div class="logo_inner" id="logo-small">
      <img id="logo" src="../../assets/img/bms-01-text.png" alt="img" />
    </div>
    <div>
      <changeAppLanguage
        v-if="$route.path.includes('signin')"></changeAppLanguage>
      <div class="form_secton">
        <!-- :class="[$route.path == '/signup' ? 'sign__up_form_secton' : '' ]" -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import SliderOne from "./animate_sections/Slider_one.vue";
  // import SliderTwo from './animate_sections/slider_two'
  import SliderThree from "./animate_sections/slider_3.vue";
  import SliderFour from "./animate_sections/slider_4.vue";
  import Slider from "./animate_sections/Slider.vue";
  export default {
    components: {
      SliderOne: SliderOne,
      // SliderTwo:SliderTwo,
      SliderThree: SliderThree,
      SliderFour: SliderFour,
      "main-slider": Slider,
    },
    data() {
      return {
        sliderComponents: [SliderOne, SliderThree, SliderFour],
        timer: null,
        currentIndex: 0,
      };
    },

    methods: {
      startSlide: function () {
        this.timer = setInterval(this.next, 5000);
      },
      next: function () {
        this.currentIndex += 1;
      },
      prev: function () {
        this.currentIndex -= 1;
      },
    },
    computed: {
      currentComponentfunction() {
        return this.sliderComponents[
          Math.abs(this.currentIndex) % this.sliderComponents.length
        ];
      },
    },
    mounted: function () {
      this.startSlide();
    },

  };
</script>

<style lang="scss" scoped>
  #logo-small {
    display: none;
  }

  .logo_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-bottom: 2.5em;
    position: relative;
    top: 20px;

    #logo {
      width: 30%;
      object-fit: contain;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.9s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;
    width: 100%;
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    visibility: hidden;
    width: 100%;
    opacity: 0;
  }

  .auth_wrapper {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10%;
  }

  .side_wrapper_with_animation {
    // width: 55%;
    // height: 100%;
    width: 55%;
    height: 80%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .slider__inner {
      width: 100%;
      padding: 0px;
    }
  }

  .form_secton {
    width: 100%;
    // height: 100vh;
    max-height: 100%;
    position: relative;
  }

  .form_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .form__header {
    text-align: center;

    h2 {
      font-family: "Graphik Regular";
      font-size: 25px;
      font-weight: bolder;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #000;
      padding-bottom: 0.5em;
    }

    p {
      color: #575761;
      font-weight: normal;
      font-size: 14px;
    }

    hr {
      background-color: #f3f3f4;
      width: 300px;
      max-width: 100%;
      opacity: 0.5;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form_inner {
    width: 450px;
    max-width: 95%;
  }

  @media screen and (max-width: 1360px) {
    .form_secton {
      width: 100%;
    }

    .side_wrapper_with_animation {
      width: 50%;
    }

    .auth_wrapper {
      padding: 0px 5%;
    }
  }

  //remove background image at 1000% and below
  @media screen and (max-width: 1000px) {
    .auth_wrapper {
      width: 100%;
      flex-direction: column;
      background: #fff;
    }

    .side_wrapper_with_animation {
      display: none;
    }

    #logo-small {
      display: block;
      margin-top: 2em;
      min-height: 5rem;
      min-width: 30rem;
      margin: 0 auto;
      object-fit: contain;
      position: relative;
      z-index: 5;
      left: 30%;
    }

    .form_secton {
      height: 100%;
      width: 100vw;
    }

    .full-screen-width {
      max-height: 100% !important;
      height: 100vh !important;
      padding-bottom: 3em;
      justify-content: flex-start;

      .form_secton {
        min-height: 650px;
        max-height: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }

    ._____form__signin,
    ._____form__reset__password {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .inner____form__sign-in,
      .___form_reset__inner {
        width: 500px;
        height: 100%;
        max-width: 90%;
        background-color: #fff;
        padding-top: 6em;
        padding-bottom: 4em;
      }
    }

    .__signUp {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3em;

      .___signup___inner {
        width: 500px;
        max-width: 90%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        padding-top: 4em;
        padding-bottom: 4em;

        .form__header {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 1360px) {
    .form_inner {
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  @media screen and (max-width: 400px) {
    .form__header h2 {
      font-size: 20px;
      line-height: 18px;
    }
  }

  .auth_wrapper {
    overflow-y: auto;
  }

  .google-translate {
    max-width: fit-content;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
</style>
