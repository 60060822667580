<script>
import appConfig from '@/app.config'
import store from '@/state/store'
export default {
    page: {
        title: 'change password',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (this.formData.password_confirmation !== this.formData.password) {
                callback(new Error('Password do not match'));
            } else {
                callback();
            }
        }
        return {
            errors: '',
            loading: false,
            type: 'alert-danger',
            formData: {
                password: "",
                password_confirmation: "",
                phone: ""
            },
            rules: {
                password: [
                    { required: true, message: 'Password is required.', trigger: ['blur', 'change'] },
                    { min: 6, message: 'Password must be at least 6 characters.', trigger: 'blur' }
                ],
                password_confirmation: [
                    { validator: validatePass2, trigger: 'blur' },
                    { required: true, message: 'Please re-type your password', trigger: 'change' },
                ]
            }
        }
    },
    props: {
        ResetPhoneNumber: {
            type: String,
            required: true
        }
    },
    methods: {
        submitForm(formName) {
            this.errors = '';
            this.loading = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // Get Phone number here
                    this.formData.phone = this.$props.ResetPhoneNumber
                    store.dispatch('auth/sendNewPasswordToServer', this.formData)
                        .then(response => {
                            this.loading = false
                            this.$notify({
                                title: 'Success',
                                message: response.data.message,
                                type: 'success'
                            });
                            // use mutation state to hide temporary reset window
                            // set default values to form data
                            this.formData.password = ""
                            this.formData.password_confirmation = ""
                            this.formData.phone=""
                            this.$refs[formName].resetFields();
                            store.commit('auth/SET_RESET_WINDOW_VISIBLE', false)
                        })
                        .catch(err => {
                            this.loading = false
                            if (err.response.data == undefined) {
                                this.errors = 'Network error try again'
                            }
                            if (err.response && err.response.status === 404) {
                                this.errors = err.response.data.data.error;
                            }
                        })
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
    }
}
</script>
<style scoped>
#account-settings {
    height: calc(100vh - 120px);
}

.profile_form {
    width: 385px;
    margin-left: 3em;
}

@media screen and (max-width: 500px) {
    .profile_form {
        width: 100%;
        ;
        margin-left: 0px;
    }
}
</style>
<template>
    <div class="col-md-12 main-section">
        <div id="account-settings" class="mnotify-card">
            <div id="side-indicators-content" class="mnotify-card-header">
                <div class="profile-settings-section-header">
                    <!-- <profilesettingsBtn /> -->
                    <div class="profile-settings-sub-header">
                        <h3 class="mnotify-card-header-text pb-2 my-0">Update Your Password</h3>
                        <p class="profile-description" style="color:red">You must update your password before logging in
                        </p>
                    </div>
                </div>
            </div>
            <div class="mnotify-card-body">
                <div class="row profile-settings-form" style="margin-top:2em;">
                    <div class="profile_form">
                        <div class="profile-inner">
                            <el-form :model="formData" :rules="rules" ref="ruleForm"  @submit.prevent.native="checkEnter">
                                <div class="form_input_container">
                                    <el-form-item label="New Password" prop="password">
                                        <el-input type="password" show-password autocomplete="off"
                                            placeholder="New Password" v-model="formData.password"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form_input_container">
                                    <el-form-item label="Confirm Password" prop="password_confirmation">
                                        <el-input type="password" show-password autocomplete="off"
                                            placeholder="Confirm Password"
                                            v-model="formData.password_confirmation"></el-input>
                                    </el-form-item>
                                </div>
                                <Alert v-if="errors" :message="errors" :type="type" />
                                <div class="terms_and_conditons_container mt-5">
                                    <el-form-item>
                                        <el-button id="mNotify_form_submit_btn" :loading="loading" type="primary"
                                            @click="submitForm('ruleForm')">Change Password</el-button>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
