<template>
    <div class="main-container">
        <div id="slider">
            <transition-group tag="div" :name="transitionName" class="slides-group">
                <div v-if="show" :key="current" class="slide">
                    <component :is="slides[current]"></component>
                </div>
            </transition-group>
            <div class="btn btn-prev" aria-label="Previous slide" @click="slide(-1)">
                &#10094;
            </div>
            <div class="btn btn-next" aria-label="Next slide" @click="slide(1)">
                &#10095;
            </div>
        </div>
    </div>
</template>
<script>
import SliderOne from './Slider_one.vue'
import SliderThree from './slider_3.vue'
import SliderFour from './slider_4.vue'
export default {
    components: {
        SliderOne: SliderOne,
        SliderThree: SliderThree,
        SliderFour: SliderFour,
    },
    data() {
        return {
            current: 0,
            direction: 1,
            transitionName: "fade",
            show: false,
            slides: [
                SliderOne,
                SliderThree,
                SliderFour
            ],
            interval: null,
        }
    },
    methods: {
        slide(dir) {
            this.direction = dir;
            dir === 1
                ? (this.transitionName = "slide-next")
                : (this.transitionName = "slide-prev");
            var len = this.slides.length;
            this.current = (this.current + dir % len + len) % len;
            // Stop interval for 1 second to prevent spamming
            clearInterval(this.interval);
            this.interval = setInterval(() => this.slide(1), 5000);
        },
    },
    mounted() {
        this.show = true;
    },
    beforeMount(){
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.interval = setInterval(() => this.slide(1), 5000);
            } else {
                clearInterval(this.interval);
            }
        });
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Crimson+Text");

/* FADE IN */
.fade-enter-active {
    transition: opacity 1s;
}

.fade-enter {
    opacity: 0;
}

/* GO TO NEXT SLIDE */
.slide-next-enter-active,
.slide-next-leave-active {
    transition: transform 0.5s ease-in-out;
}

.slide-next-enter {
    transform: translate(100%);
}

.slide-next-leave-to {
    transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: transform 0.5s ease-in-out;
}

.slide-prev-enter {
    transform: translate(-100%);
}

.slide-prev-leave-to {
    transform: translate(100%);
}

/* SLIDES CLASSES */

/* SLIDER STYLES */
body {
    overflow: hidden;
    margin: 0;
    font-size: 50px;
    font-family: "Crimson Text", sans-serif;
    color: #fff;
}

#slider {
    width: 100%;
    height: 100vh;
    position: relative;
}

.slide {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    z-index: 10;
    cursor: pointer;
    border: 3px solid rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 35px);
    left: 7%;
    transition: transform 0.3s ease-in-out;
    user-select: none;
    color: white;
}

.btn-next {
    left: auto;
    right: 7%;
    
}

.btn:hover {
    transform: scale(1.1);
}

.main-container {
    overflow: hidden;
}
</style>