<template>
  <div class="notranslate">
    <div class="__silder_wraper ">
      <div class="slider_text">
        <h2 class="mb-4" v-html="$t('auth.sliders.slide_3_header')"></h2>
        <p v-html="$t('auth.sliders.slide_3_text')"></p>
      </div>
      <div class="slider_image">
        <img
          class="image"
          src="../../../assets/img/Sent-Message-bro.png"
          alt="illustrative image of sending a message"
          width="1"
          height="1" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .__silder_wraper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slider_text {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // background-color: saddlebrown;
    height: 150px;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 40px;
      color: #000000;
      text-align: center;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 30px;
      color: #000000;
      opacity: 0.4;
      max-width: 450px;
      text-align: center;
    }
  }

  .slider_image {
    width: 100%;
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image {
      width: 450px;
      max-width: 80%;
      object-fit: contain;
      height: 100%;
      background-origin: center;
    }
  }

  .iconbutton {
    width: 42px;
    height: 42px;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;

    &:hover,
    &:active {
      background: #000;
    }

    img {
      width: 100%;
    }
  }
  .slider_image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
