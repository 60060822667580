<template>
  <div id="mnotify__form">
      <div id="mNotify__form_image_container">
          <div class="side-image-background">
              <div class="logo_container">
                  <img src="../../assets/mnotify logo.svg" height="30" width="120" alt="img">
              </div>
          </div>
      </div>
      <div id="mNotify__form_fields_container"  ref="formContainer">
          <div class="mnotify__form_upper_link">
            <p class="text_right_corner">Don’t have an account? <router-link to="/account/signup" class="account_link">Sign Up</router-link> </p>
          </div>
          <div class="mnotify_form__inner__wrapper">
            <div class="mNotify__form_fields_inner_container">
              <h2 class="form_title">Reset your password</h2>
              <p class="reset_password_text">Enter the phone number associated with your account and we’ll send you a link to reset your password</p>
              <hr class="form_line">
                <form action="">
                  <div class="form_input_container tel_input">
                    <label class="form_input_label" for="phone">Phone Number</label>
                    <select class="form_country_code_select" name="code" id="code"></select>
                    <input class="form_country_code_phone_number" type="text" placeholder="Enter your phone number">
                  </div>
                  <div>
                    <button class="form_action_btn">Reset</button>
                  </div>
                  <div class="text-center return_to_login mt-2">
                      <router-link to="/account/login" class=""> Return to sign in</router-link>
                  </div>
                </form>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

